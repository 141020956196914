<template>
  <form
      @submit.prevent="submitGoodsIn"
      autocomplete="off"
      class="w-full max-h-screen bg-background flex flex-col relative"
      :class="{ 'overflow-y-hidden': isBusy }"
  >
    <div
        v-show="isBusy"
        class="absolute inset-0 bg-faint-white flex items-center justify-center z-10"
    >
      <spinner :color="'text-black'" :size="10"/>
    </div>
    <page-header :title="pageTitle" back-to="goods-in">

      <!--      <AppButton color="green" label="Print Label" @click="printGoodsLabel" icon="printer" class="mr-2">-->
      <!--        <template v-slot:icon>-->
      <!--          <Icon iconType="print" class="mr-2"/>-->
      <!--        </template>-->
      <!--      </AppButton>-->

      <button
          @click="saveGoodsIn"
          type="button"
          class="flex items-center bg-blue-200 border hover:bg-blue-300 border-blue-400 hover:border-blue-500 hover:shadow active:shadow-inner transition duration-300 text-secondary rounded-md px-2 py-1"
      >
        <svg
            class="stroke-current h-5 w-5 mr-2"
            fill="none"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            viewBox="0 0 24 24"
        >
          <path
              d="M19 21H5a2 2 0 01-2-2V5a2 2 0 012-2h11l5 5v11a2 2 0 01-2 2z"
          />
          <path d="M17 21v-8H7v8M7 3v5h8"/>
        </svg>
        <span>Save</span>
      </button>

    </page-header>

    <main class="p-6 bg-background flex-1 overflow-y-scroll">
      <!-- Goods In Details -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Goods In Details
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <div class="flex flex-wrap -my-3 -mx-5">
            <div class="w-full flex flex-col mt-3">
              <div class="flex flex-wrap">
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                  >RECEIVED BY <span class="text-red-400">*</span></label
                  >
                  <StaffAutocomplete v-model="goods_in.received_by"/>
                </div>
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm">SENDER <span class="text-red-400">*</span></label>
                  <ContactAutocomplete v-model="goods_in.sender" :is_agent="true"/>
                </div>
                <div class="w-full sm:w-1/2 lg:w-1/3 flex flex-col px-5 mb-3">
                  <label class="uppercase font-semibold text-sm"
                  >COURIER <span class="text-red-400">*</span>
                  </label>
                  <div class="inline-block relative w-full">
                    <select
                        v-model="goods_in.courier"
                        class="bge-input bge-select rounded w-full"
                    >
                      <option>AMAZON</option>
                      <option>APC</option>
                      <option>CUSTOMER</option>
                      <option>DHL</option>
                      <option>DPD</option>
                      <option>DX</option>
                      <option>ENGINEER</option>
                      <option>FEDEX</option>
                      <option>HERMES</option>
                      <option>KEYES</option>
                      <option>MENZIES</option>
                      <option>OTHER</option>
                      <option>PALLET FORCE</option>
                      <option>PARCEL FORCE</option>
                      <option>ROYAL MAIL</option>
                      <option>SUPPLIER</option>
                      <option>TNT</option>
                      <option>UNKNOWN</option>
                      <option>UPS</option>
                      <option>WREFORDS</option>
                      <option>YODEL</option>
                    </select>
                    <div
                        class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                    >
                      <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                        <path
                            d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm"
              >CUSTOMER / OWNER</label
              >
              <ContactAutocomplete v-model="goods_in.customer" :is_customer="true"/>

            </div>
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm"
              >INTENDED RECIPIENT <span class="text-red-400">*</span>
              </label>
              <div class="inline-block relative w-full">
                <select
                    v-model="goods_in.intended_recipient"
                    class="bge-input bge-select rounded w-full"
                >
                  <option>
                    ACCOUNTS DEPARTMENT
                  </option>
                  <option>ADMIN DEPARTMENT</option>
                  <option>ENGINEERS</option>
                  <option>PRODUCTION</option>
                  <option>REPAIR DEPARTMENT</option>
                  <option>
                    RESEARCH & DEVELOPMENT
                  </option>
                  <option>SERVICE DEPARTMENT</option>
                  <option>WAREHOUSE</option>

                </select>
                <div
                    class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700"
                >
                  <svg class="fill-current h-4 w-4" viewBox="0 0 20 20">
                    <path
                        d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"
                    />
                  </svg>
                </div>
              </div>
            </div>
            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm"
              >ITEM SERIAL NUMBER</label
              >
              <input
                  v-model="goods_in.serial_number"
                  class="bge-input bge-input-spacing rounded"
                  type="text"
              />
            </div>

            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm"
              >CONSIGNMENT NUMBER</label
              >
              <input
                  v-model="goods_in.consignment_number"
                  class="bge-input bge-input-spacing rounded"
                  type="text"
              />
            </div>

            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <label class="uppercase font-semibold text-sm"
              >INVOICE / WARRANTY NUMBER</label
              >
              <input
                  v-model="goods_in.invoice_or_warranty_number"
                  class="bge-input bge-input-spacing rounded"
                  type="text"
              />
            </div>

            <div class="flex flex-col w-full sm:w-1/2 lg:w-1/3 my-3 px-5">
              <div class="flex w-full "> <!-- Flex container for 70-30 split -->
                <!-- Text box with label taking up 70% -->
                <div class="flex flex-col w-3/5 pr-2 ">
                  <label class="uppercase font-semibold text-sm">Return Number</label>
                  <input
                      v-model="goods_in.return_number"
                      class="bge-input bge-input-spacing  w-11/12"
                      type="text"
                  />
                </div>

                <!-- Toggle in the remaining 30% -->
                <div class="w-2/5 flex flex-col justify-center items-end ">
                  <Toggle class="mt-6 mr-2"
                          label="Confirmed"
                          :checked="goods_in.is_confirmed"
                          @value-changed="(value) => goods_in.is_confirmed = value"
                          labelPosition="left"
                          labelClasses="uppercase font-semibold text-sm"
                  />
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <!-- Item Description -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Item Description
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <Editor v-model="goods_in.description" editorStyle="height: 120px" :options="editorOptions"
                  ref="descriptionEditor">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
              </span>
            </template>
          </Editor>
        </div>
      </div>

      <!-- Notes -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Notes
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <Editor editorStyle="height: 120px" v-model="goods_in.notes" :options="editorOptions">
            <template #toolbar>
              <span class="ql-formats">
                <button class="ql-bold"></button>
                <button class="ql-italic"></button>
                <button class="ql-underline"></button>
                <button class="ql-list" value="ordered"></button>
                <button class="ql-list" value="bullet"></button>
              </span>
            </template>
          </Editor>
        </div>
      </div>

      <!-- Manage Files -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Manage Files
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>

          <Galleria
              :value="imageFiles"
              :activeIndex.sync="activeIndex"
              :responsiveOptions="responsiveOptions"
              :numVisible="7"
              containerStyle="max-width: 850px"
              :circular="true"
              :fullScreen="true"
              :showItemNavigators="true"
              :showThumbnails="false"
              :visible.sync="displayCustom"
          >
            <template #item="slotProps">
              <img
                  :src="slotProps.item.access_url"
                  :alt="slotProps.item.name"
                  style="width: 100%; display: block"
              />
            </template>
            <template #thumbnail="slotProps">
              <img
                  :src="slotProps.item.access_url_thumbnail"
                  :alt="slotProps.item.name"
                  style="display: block"
              />
            </template>
            <!--            <template #caption="{ item }">-->
            <!--              <h4 style="margin-bottom: 0.5rem">{{ item.fileType }}</h4>-->
            <!--              <p>{{ item.alt }}</p>-->
            <!--            </template>-->
          </Galleria>

          <div v-if="sortedFiles">
            <div class="flex flex-wrap gap-2 justify-start">
              <div
                  v-for="(file, index) in sortedFiles"
                  :key="index"
                  class="relative"
                  style="width: 100px; height: 100px"
              >
                <!-- Conditional rendering based on file type -->
                <img
                    v-if="isImage(file)"
                    :src="file.access_url_thumbnail"
                    :alt="file.name"
                    :class="imageClass(file)"
                    class="cursor-pointer"
                    @click="editMode ? null : imageClick(index)"
                />

                <!-- PDF Icon -->
                <div
                    @click="editMode ? null : pdfClick(file)"
                    v-else-if="isPDF(file)"
                    class="flex items-center justify-center border-4 border-orange-300 cursor-pointer"
                    style="width: 100px; height: 100px"
                >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="60"
                      height="60"
                      fill="none"
                      stroke="#ff2825"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      class="icon icon-tabler icon-tabler-file-type-pdf"
                      viewBox="0 0 24 24"
                  >
                    <path stroke="none" d="M0 0h24v24H0z"/>
                    <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
                    <path
                        d="M5 12V5a2 2 0 0 1 2-2h7l5 5v4M5 18h1.5a1.5 1.5 0 0 0 0-3H5v6M17 18h2M20 15h-3v6M11 15v6h1a2 2 0 0 0 2-2v-2a2 2 0 0 0-2-2h-1z"
                    />
                  </svg>
                </div>

                <!-- Document Icon -->
                <div
                    @click="editMode ? null : docClick(file)"
                    v-else
                    class="flex items-center justify-center border-4 border-orange-300 cursor-pointer"
                    style="width: 100px; height: 100px"
                >
                  <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="icon icon-tabler icon-tabler-file-type-doc"
                      width="60"
                      height="60"
                      viewBox="0 0 24 24"
                      stroke-width="1"
                      stroke="#00abfb"
                      fill="none"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                    <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
                    <path d="M5 12v-7a2 2 0 0 1 2 -2h7l5 5v4"/>
                    <path d="M5 15v6h1a2 2 0 0 0 2 -2v-2a2 2 0 0 0 -2 -2h-1z"/>
                    <path d="M20 16.5a1.5 1.5 0 0 0 -3 0v3a1.5 1.5 0 0 0 3 0"/>
                    <path
                        d="M12.5 15a1.5 1.5 0 0 1 1.5 1.5v3a1.5 1.5 0 0 1 -3 0v-3a1.5 1.5 0 0 1 1.5 -1.5z"
                    />
                  </svg>
                </div>

                <!-- Checkbox for Edit Mode -->
                <div
                    v-if="editMode"
                    class="absolute bottom-0 left-0 right-0 flex justify-center"
                    style="padding-bottom: 5px"
                >
                  <Checkbox v-model="selectedFiles" :value="file.unique_id"/>
                </div>
              </div>

              <!-- Trigger for File Upload with Custom SVG Icon -->
              <div
                  class="flex items-center justify-center border-4 border-grey-300 cursor-pointer"
                  @click="addNewFile"
                  style="width: 100px; height: 100px"
              >
                <!-- Custom SVG Icon -->
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="60"
                    height="60"
                    fill="none"
                    stroke="#ff9300"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="icon icon-tabler icon-tabler-file-plus"
                    viewBox="0 0 24 24"
                >
                  <path stroke="none" d="M0 0h24v24H0z"/>
                  <path d="M14 3v4a1 1 0 0 0 1 1h4"/>
                  <path
                      d="M17 21H7a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h7l5 5v11a2 2 0 0 1-2 2zM12 11v6M9 14h6"
                  />
                </svg>
              </div>
            </div>
          </div>
          <!-- Hidden File Input -->
          <input
              type="file"
              multiple
              ref="fileInput"
              @change="handleFileSelection"
              class="hidden"
          />

          <!-- Upload and Cancel Buttons -->
          <div
              v-if="files.length"
              class="mx-auto flex justify-end mt-2"
          >
            <AppButton v-if="!editMode" color="blue" label="Edit" @click="enableFileSelection" :icon=true class="mr-2">
              <template v-slot:icon>
                <Icon iconType="edit" class="mr-2"/>
              </template>
            </AppButton>
            <AppButton v-if="editMode" color="red" label="Delete" @click="deleteSelectedFiles" :icon=true class="mr-2"
                       :badge="getSelectedFilesCount" badgeType="danger">
              <template v-slot:icon>
                <Icon iconType="delete" class="mr-2"/>
              </template>
            </AppButton>
            <!--            <Button-->
            <!--                v-if="editMode"-->
            <!--                @click="deleteSelectedFiles"-->
            <!--                type="button"-->
            <!--                label="Delete"-->
            <!--                badgeClass="p-badge-danger"-->
            <!--                :badge="getSelectedFilesCount"-->
            <!--                class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded mr-2"-->
            <!--            >-->
            <!--            </Button>-->
            <AppButton color="gray" label="Cancel" @click="cancelFileSelection" :icon=true class="mr-2">
              <template v-slot:icon>
                <Icon iconType="close" class="mr-2"/>
              </template>
            </AppButton>
            <!--            <Button-->
            <!--                @click="cancelFileSelection"-->
            <!--                type="button"-->
            <!--                label="Cancel"-->
            <!--                class="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded mr-2"-->
            <!--            >-->
            <!--            </Button>-->
            <!--            <Button-->
            <!--                :disabled="editMode"-->
            <!--                @click="uploadFiles"-->
            <!--                :badge="getNewFilesCount"-->
            <!--                type="button"-->
            <!--                badgeClass="p-badge-success"-->
            <!--                label="Upload"-->
            <!--                class="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded"-->
            <!--            >-->
            <!--            </Button>-->

            <AppButton :disabled="editMode" color="green" label="Upload" @click="uploadFiles" :icon=true class="mr-2"
                       :badge="getNewFilesCount" badgeType="success">
              <template v-slot:icon>
                <Icon iconType="upload" class="mr-2"/>
              </template>
            </AppButton>
          </div>
        </div>
      </div>


      <!-- Manage Print -->
      <div class="w-full bg-white mt-6 p-5 pt-1 rounded shadow-md relative">
        <div class="mt-6">
          <div class="flex items-center mb-5">
            <h3
                class="uppercase text-lg font-semibold text-primary tracking-wide"
            >
              Print Label
            </h3>
            <hr class="flex-1 border-primary ml-4"/>
          </div>
          <p class="mb-2">Label preview:</p>

          <!-- Label Preview Section -->
          <div class="flex">
            <div class="w-full max-w-lg">
              <div class="p-inputtext rounded overflow-hidden">
                <div class="p-4 bg-cool-gray-50"
                     :class="{'opacity-100': labelPreviewSuccess, 'opacity-50': !labelPreviewSuccess}">
                  <div class="flex justify-center items-center">
                    <template v-if="labelPreviewSuccess">
                      <img :src="labelPreviewImage" alt="Label Preview" class="w-full h-auto"/>
                    </template>
                    <template v-else>
                      <span class="text-white-500 font-medium">No preview available</span>
                    </template>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <!-- Upload and Cancel Buttons -->
          <div class="mx-auto flex justify-end mt-4">
            <AppButton color="green" label="Preview Label" @click="prepareAndPreviewLabel" class="mr-2"
            >
            </AppButton>

            <AppButton :disabled="!labelPreviewSuccess" color="blue" label="Print Label" @click="printLabel"
                       :icon=true
                       class="mr-2">
              <template v-slot:icon>
                <Icon iconType="print" class="mr-2"/>
              </template>
            </AppButton>

          </div>
        </div>
      </div>

    </main>

    <portal to="overlay-outlet">
      <ViewDocument
          :showing="viewingDocument.panelOpen"
          :document="viewingDocument.doc"
          @close="viewingDocument.panelOpen = false"
      />
    </portal>
  </form>
</template>

<script>

import PageHeader from "@/components/PageHeader.vue";
import Panel from "@/components/Panel.vue";
import Notification from "@/components/Notification.vue";
import Spinner from "@/components/Spinner.vue";
import AddEditPeople from "@/components/Internal/HealthAndSafety/Incident/AddEditPeople.vue";
import ContactAutocomplete from '@/components/AutoComplete/ContactAutoComplete.vue';
import StaffAutocomplete from '@/components/AutoComplete/StaffAutoComplete.vue';
import AppButton from '@/components/Button.vue';
import Icon from '@/components/Icon.vue';

const Toggle = () => import("@/components/Toggle.vue");

const Datepicker = () => import("vuejs-datepicker");
const ViewDocument = () => import("@/components/ViewDocument.vue");
import notificationMixin from "@/mixins/notificationMixin";
import Pica from "pica";

export default {
  name: "ManageGoodsIn",
  mixins: [notificationMixin],
  components: {
    PageHeader,
    Panel,
    Spinner,
    AddEditPeople,
    Datepicker,
    ViewDocument,
    ContactAutocomplete,
    StaffAutocomplete,
    AppButton,
    Icon,
    Toggle,
  },
  props: {
    goods_in_id: String,
  },
  data() {
    return {
      selectedSender: null,
      checked: true,
      showingAddPeoplePanel: false,
      isBusy: false,
      incidentPeopleTitle: "",
      editingPeople: {},
      newIncident: {
        connected_people: [],
      },
      goods_in: {
        received_by: null
      },
      editMode: false,
      selectedFiles: [],
      activeIndex: 0,
      displayCustom: false,
      responsiveOptions: [
        // Define your responsive options here
      ],
      viewingDocument: {
        doc: {},
        panelOpen: false,
      },
      files: [],
      editorOptions: {
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline'],
            [{'list': 'ordered'}, {'list': 'bullet'}],
          ]
        },
        theme: 'snow'
      },
      labelPreview: '',
      labelPreviewImage: '',
      labelPreviewSuccess: false,
    };
  },
  async mounted() {
    this.isBusy = true;
    if (!this.goods_in_id) {

      if (this.$store.state.user && this.$store.state.user.staff_id) {
        this.goods_in.received_by = {
          name: `${this.$store.state.user.first_name} ${this.$store.state.user.last_name}`,
          staff_id: this.$store.state.user.staff_id
        };
      }
      this.isBusy = false;
      return;
    }
    await this.loadGoodsIn();
    await this.loadFiles();

    this.isBusy = false;

    if (dymo.label.framework.init) {
      dymo.label.framework.init();
    }

  },
  computed: {
    imageFiles() {
      // Filter out only image files from the sorted files
      return this.sortedFiles.filter((file) => this.isImage(file));
    },
    sortedFiles() {
      console.log("Recalculating sorted files");
      // Clone the files array to avoid modifying the original array
      const filesClone = [...this.files];

      // Define a custom sort function
      const sortFunction = (a, b) => {
        if (this.isImage(a) && !this.isImage(b)) return -1;
        if (!this.isImage(a) && this.isImage(b)) return 1;
        if (this.isPDF(a) && !this.isPDF(b)) return -1;
        if (!this.isPDF(a) && this.isPDF(b)) return 1;
        return 0;
      };

      // Sort the cloned array
      const sortedFiles = filesClone.sort(sortFunction);
      console.log(sortedFiles);
      return sortedFiles;
    },
    pageTitle() {
      return this.goods_in_id ? "Update Goods In" : "Create Goods In";
    },
    getNewFilesCount() {
      if (!this.files || this.files.length === 0) {
        return "0";
      }
      return this.files.filter((file) => file.isNew === true).length.toString();
    },
    getSelectedFilesCount() {
      if (!this.selectedFiles || this.selectedFiles.length === 0) {
        return "0";
      }
      return this.selectedFiles.length.toString();
    },

  },
  methods: {
    async loadGoodsIn() {
      if (!this.goods_in_id)
        return false;

      const goodsIn = await this.GoodsInService.getGoodsInById(
          this.goods_in_id,
      );
      this.goods_in = goodsIn;
    },
    async loadFiles() {
      if (!this.goods_in_id)
        return false;

      const files = await this.GoodsInService.getGoodsInDocuments(
          this.goods_in_id,
      );
      const filesWithUniqueId = files.map((file, index) => ({
        ...file,
        unique_id: this.generateUniqueId(),
      }));

      this.files = filesWithUniqueId;
    },
    enableFileSelection() {
      this.editMode = true;
      if (!this.editMode) {
        this.selectedFiles = [];
      }
    },
    cancelFileSelection() {
      this.editMode = false;
      this.selectedFiles = [];
    },
    async deleteSelectedFiles() {
      try {
        if (this.selectedFiles.length === 0) {
          this.notifyWarning("No files selected to delete.");
          return;
        }

        const confirmed = await this.confirmAction({
          message: "Are you sure you want to delete the selected files?",
          // You can add more details to the message if necessary
        });

        // Only proceed if the action is confirmed
        if (!confirmed) {
          return;
        }

        const selectedFilesSet = new Set(this.selectedFiles);
        let filesDeleted = false;

        // Delete local files
        const localFilesToDelete = this.files.filter(
            (file) => file.isNew && selectedFilesSet.has(file.unique_id),
        );
        if (localFilesToDelete.length > 0) {
          this.files = this.files.filter(
              (file) => !selectedFilesSet.has(file.unique_id),
          );
          this.selectedFiles = this.selectedFiles.filter(
              (id) => !localFilesToDelete.some((file) => file.unique_id === id),
          );
          filesDeleted = true;
        }

        // Delete server files
        if (this.goods_in_id) {
          const serverFilesToDelete = this.files
              .filter((file) => !file.isNew && selectedFilesSet.has(file.unique_id))
              .map((file) => file.document_id);

          if (serverFilesToDelete.length > 0) {
            await this.GoodsInService.deleteGoodsInDocuments(
                this.goods_in_id,
                serverFilesToDelete,
            );
            this.selectedFiles = this.selectedFiles.filter(
                (unique_id) =>
                    !this.files.some(
                        (file) =>
                            file.document_id &&
                            serverFilesToDelete.includes(file.document_id) &&
                            file.unique_id === unique_id,
                    ),
            );

            filesDeleted = true;
          }
        }

        // Handling messages
        if (filesDeleted) {
          this.notifySuccess("Selected files removed successfully");
          await this.loadFiles();
          this.editMode = false;
        } else {
          this.notifyWarning("No documents selected for deletion");
        }
      } catch (error) {
        console.error("Failed to delete documents:", error);
        this.notifyError("Failed to delete documents");
      } finally {
      }
    },
    imageClass(file) {
      const isSelected = this.selectedFiles.includes(file.unique_id);
      return [
        "w-full h-full object-cover",
        {
          "border-4 border-gray-500": this.editMode && !isSelected,
          "border-4 border-green-500": file.isNew,
          "border-4 border-red-500": isSelected,
        },
      ];
    },
    isImage(file) {
      return ["jpg", "jpeg", "png", "gif", "bmp"].includes(
          file.content_type.toLowerCase(),
      );
    },
    isPDF(file) {
      return file.content_type.toLowerCase() === "pdf";
    },
    isDocument(file) {
      const docTypes = ["doc", "docx", "txt", "odt", "rtf"];
      return (
          !this.isImage(file) &&
          !this.isPDF(file) &&
          docTypes.includes(file.content_type.toLowerCase())
      );
    },
    fileClick(index) {
      this.activeIndex = index;
      this.displayCustom = true;
    },
    getFileExtension(file) {
      return file.name
          ? file.name.split(".").pop()
          : file.access_url.split(".").pop();
    },
    addNewFile() {
      if (this.editMode) {
        this.notifyWarning("Cannot add new files while in edit mode.");
        return;
      }
      this.openFileUpload();
    },
    openFileUpload() {
      this.$refs.fileInput.click();
    },
    handleFileSelection() {
      const selectedFiles = Array.from(this.$refs.fileInput.files);
      selectedFiles.forEach((file) => {
        const fileUrl = URL.createObjectURL(file);
        const fileType = this.getFileExtension(file);
        const uniqueId = this.generateUniqueId();
        this.files.push({
          access_url: fileUrl,
          access_url_thumbnail: fileUrl,
          name: file.name,
          isNew: true,
          content_type: fileType,
          unique_id: uniqueId,
          original_file: file,
        });
      });

      this.resetFileInput();
    },
    async uploadFiles() {
      console.log("Uploading", this.files);
      const newFilesToUpload = this.files.filter((file) => file.isNew);

      if (!newFilesToUpload || newFilesToUpload.length === 0) {
        this.notifyWarning("No new files to upload.");
        return;
      }

      await this.uploadDocumentWithThumbnail(newFilesToUpload);
      await this.loadFiles();
    },
    resetFileInput() {
      if (this.$refs.fileInput) {
        this.$refs.fileInput.value = "";
      }
    },
    docClick(file) {
      const link = document.createElement('a');
      link.href = file.access_url;
      link.download = file.filename;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
    },
    imageClick(index) {
      this.activeIndex = index;
      this.displayCustom = true;
    },
    pdfClick(file) {
      if (file.isNew) return;

      this.viewingDocument = {
        doc: file,
        panelOpen: true,
      };
    },
    generateUniqueId() {
      return `id_${Date.now()}_${Math.random().toString(36).slice(2, 11)}`;
    },
    async uploadDocumentWithThumbnail(editingDoc) {
      this.isBusy = true;
      try {
        if (!this.goods_in_id) {
          this.notifyError(
              "Please save the Goods In Details before upload docs.",
          );
          return;
        }

        const uploadResults = await this.GoodsInService.uploadDocuments(
            editingDoc,
            this.goods_in_id,
        );

        for (const uploadResult of uploadResults) {
          const fileToUpload = this.files.find(
              (file) => file.unique_id === uploadResult.unique_id,
          );
          if (fileToUpload) {

            // Upload the main document
            if (uploadResult.access_url) {
              await this.uploadFileToBlob(
                  uploadResult.access_url,
                  fileToUpload.original_file,
              );

            }

            // Upload the thumbnail, if applicable
            if (fileToUpload && uploadResult.access_url_thumbnail) {

              console.log("Original file size:", fileToUpload.size, "bytes");
              const thumbnailFile = await this.resizeImage(
                  fileToUpload.original_file,
              );
              console.log("Thumbnail size:", thumbnailFile.size, "bytes");

              await this.uploadFileToBlob(
                  uploadResult.access_url_thumbnail,
                  thumbnailFile,
              );
            }
          }
        }

        this.notifySuccess("Document and thumbnail uploaded successfully.");
      } catch (error) {
        console.error("Upload error:", error);
        this.notifyError("There was an error uploading the document.");
      } finally {
        this.isBusy = false;
      }
    },
    // Utility method to upload a file to Azure Blob Storage using a SAS URL
    async uploadFileToBlob(sasUrl, file) {

      const url = new URL(sasUrl);
      const accountUrl = url.origin;
      const sasToken = url.search;
      const pathParts = url.pathname.split("/").filter((p) => p.trim() !== "");
      const container = pathParts[0];
      const filename = pathParts.slice(1).join("/");

      const blobService = AzureStorage.Blob.createBlobServiceWithSas(
          accountUrl,
          sasToken,
      );
      const customBlockSize =
          file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 128;
      blobService.singleBlobPutThresholdInBytes = customBlockSize;

      return new Promise((resolve, reject) => {
        blobService.createBlockBlobFromBrowserFile(
            container,
            filename,
            file,
            {blockSize: customBlockSize},
            (error) => {
              if (error) {
                reject(error);
              } else {
                resolve();
              }
            },
        );
      });
    },

    async resizeImage(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = async (e) => {
          const img = new Image();
          img.onload = async () => {
            const pica = Pica();

            // Define your maximum dimensions
            const maxWidth = 100;
            const maxHeight = 100;

            const canvas = document.createElement("canvas");
            const ratio = Math.min(
                maxWidth / img.width,
                maxHeight / img.height,
            );
            canvas.width = img.width * ratio;
            canvas.height = img.height * ratio;

            try {
              await pica.resize(img, canvas);
              canvas.toBlob(
                  (blob) => {
                    const resizedFile = new File([blob], `resized_${file.name}`, {
                      type: "image/jpeg", // Ensure this matches the format used in toBlob
                      lastModified: Date.now(),
                    });
                    resolve(resizedFile);
                  },
                  "image/jpeg", // This should match the MIME type of the resized file
                  0.9,
              );
            } catch (err) {
              reject(err);
            }
          };
          img.src = e.target.result;
        };
        reader.onerror = (err) => {
          reject(err);
        };
        reader.readAsDataURL(file);
      });
    },

    async loadIncidents() {
      let incident = await this.IncidentService.getIncidentById(
          this.incident_id,
      );
      this.newIncident = incident;
    },
    savePeople: function (people) {
      this.showingAddPeoplePanel = false;
      let existingPeople = this.$_.find(
          this.newIncident.connected_people,
          (ppl) => ppl.staff_id == people.staff_id,
      );
      if (existingPeople) {
        existingPeople.first_name = people.first_name;
        existingPeople.last_name = people.last_name;
        existingPeople.role = people.role;
        existingPeople.email = people.email;
        existingPeople.contact_no = people.contact_no;
        existingPeople.comment = people.comment;
        existingPeople.staff_id = people.staff_id;
      } else {
        this.newIncident.connected_people.push(people);
      }
    },
    editPeople: function (people) {
      this.incidentPeopleTitle = "Update People";
      this.editingPeople = {
        ...people,
      };
      this.showingAddPeoplePanel = true;
    },
    removePeople: function (people) {
      this.$_.remove(
          this.newIncident.connected_people,
          (ppl) => ppl.staff_id == people.staff_id,
      );
      this.$forceUpdate();
    },

    submitGoodsIn: async function (event) {
      event.preventDefault();
    },


    isValidGoodsIn: function (goodsIn) {
      return goodsIn.sender && Object.keys(goodsIn.sender).length > 0 &&
          goodsIn.received_by && Object.keys(goodsIn.received_by).length > 0 &&
          typeof goodsIn.courier === 'string' && goodsIn.courier.trim() !== '' &&
          typeof goodsIn.intended_recipient === 'string' && goodsIn.intended_recipient.trim() !== '';
    },

    saveGoodsIn: async function () {
      this.isBusy = true;

      try {

        if (!this.isValidGoodsIn(this.goods_in)) {
          this.notifyUser("danger", "Please fill in all required fields.");
          return;
        }

        if (this.goods_in_id) {
          await this.GoodsInService.updateGoodsIn(
              this.goods_in_id,
              this.goods_in,
          );
          this.notifyUser("success", "Goods In updated successfully");
        } else {
          await this.GoodsInService.createGoodsIn(this.goods_in);
          this.notifyUser("success", "Goods In created successfully");
        }
        await this.$router.push({name: "goods-in"});
      } catch (error) {
        let errorMessage = this.incident_id
            ? "Couldn't update Goods In"
            : "Couldn't create Goods In";
        this.notifyUser("danger", errorMessage);
        console.error(error);
      } finally {
        this.isBusy = false;
      }
    },

    notifyUser: function (type, message) {
      this.$breadstick.notify(
          ({h, onClose}) => {
            return h(Notification, {
              props: {
                type: type,
                title: message,
                close: onClose,
              },
            });
          },
          {position: "top-right"},
      );
    },

    async updateLabelPreview(label) {
      this.labelPreviewSuccess = false;
      try {
        const pngData = label.render();
        this.labelPreviewImage = "data:image/png;base64," + pngData;
        this.labelPreviewSuccess = true;
      } catch (error) {
        console.error("Error updating label preview: ", error);
        this.notifyWarning("Failed to update label preview.");
      }
    },

    formatTimestamp(unixTimestamp) {
      return this.$moment.unix(unixTimestamp).format('DD-MM-YYYY');
    },
    prepareLabelData(goods_in) {
      const data = {
        date: goods_in.created_at ? this.formatTimestamp(goods_in.created_at) : '',
        serialNumber: goods_in.serial_number || '',
        sender: goods_in.sender && goods_in.sender.name ? goods_in.sender.name : '',
        recipient: goods_in.intended_recipient || '',
        description: this.$refs.descriptionEditor.quill.getText(),
      };

      return data;
    },

    async getBaseLabelXml() {
      try {
        const response = await fetch('/label/goods-in-label.dymo');
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const xmlText = await response.text();
        return xmlText;
      } catch (error) {
        console.error("Failed to load label XML: ", error);
        this.notifyWarning("Failed to load label template.");
        return null;
      }
    },

    async prepareAndPreviewLabel() {
      if (!this.$store.state.labelPrinter) {
        this.notifyWarning('Please select a printer.');
        return;
      }

      const labelData = this.prepareLabelData(this.goods_in);
      const labelXml = await this.getBaseLabelXml();
      if (!labelXml) return;

      try {
        const label = dymo.label.framework.openLabelXml(labelXml);

        label.setObjectText('TextObject5', labelData.date || '');
        label.setObjectText('TextObject6', labelData.serialNumber || '');
        label.setObjectText('TextObject7', labelData.sender || '');
        label.setObjectText('TextObject8', labelData.recipient || '');
        label.setObjectText('TextObject9', labelData.description || '');

        this.labelPreview = label; // Save the prepared label for printing
        await this.updateLabelPreview(label);  // Assuming this function updates a visual preview
        // this.notifySuccess('Preview is ready.');
      } catch (error) {
        console.error("Preview Error: ", error);
        this.notifyWarning(`Error preparing label preview: ${error.message || error}`);
      }
    },
    async printLabel() {
      if (!this.labelPreview) {
        this.notifyWarning('No preview available. Please generate a preview first.');
        return;
      }

      try {
        this.labelPreview.print(this.$store.state.labelPrinter);
        this.notifySuccess('Label has been successfully sent to the printer.');
      } catch (error) {
        console.error("Printing Error: ", error);
        this.notifyWarning(`Error printing label: ${error.message || error}`);
      }
    },

    async printGoodsLabel() {
      if (!this.$store.state.labelPrinter) {
        this.notifyWarning('Please select a printer.');
        return;
      }

      const labelData = this.prepareLabelData(this.goods_in);
      const labelXml = await this.getBaseLabelXml();
      if (!labelXml) return;

      try {

        const label = dymo.label.framework.openLabelXml(labelXml);

        label.setObjectText('TextObject5', labelData.date || '');
        label.setObjectText('TextObject6', labelData.serialNumber || '');
        label.setObjectText('TextObject7', labelData.sender || '');
        label.setObjectText('TextObject8', labelData.recipient || '');
        label.setObjectText('TextObject9', labelData.description || '');
        await this.updateLabelPreview(label);
        label.print(this.$store.state.labelPrinter);
        this.notifySuccess('Label has been successfully sent to the printer.');
      } catch (error) {
        console.error("Printing Error: ", error);
        this.notifyWarning(`Error printing label: ${error.message || error}`);
      }
    },
  },
};
</script>
<style>
.image-checkbox-container {
  position: relative;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.file-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.checkbox-container {
  text-align: center;
  padding-top: 0.25rem; /* Adjust as needed */
}

.checkbox {
  display: inline-block;
  width: 1.5rem; /* Adjust size as needed */
  height: 1.5rem;
}

.upload-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px dashed gray;
  cursor: pointer;
}

.upload-icon {
  width: 64px;
  height: 64px;
}
</style>
